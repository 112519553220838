import React, { useState } from 'react'
import axios from 'axios'
import Container from 'react-bootstrap/Container'

export default function(){
  const [input,setInput] = useState('');
  const [selectedOption, setSelectedOption] = useState();

  function Request(body){
    console.log(body)
    let endpoint = `/${selectedOption}/add`
    if(window.location.href.includes('localhost')){
      endpoint = `http://localhost:4202/${selectedOption}/add`
    }
  
    axios({
      method: 'post',
      url: endpoint,
      data: {
        title: input,
        url: input
      }
    }).then(r=>{
      console.log(r)
      alert(r.data)
    })
  }

  function onKeyPress(e){
    if(e.key==='Enter') onClick()
  }

  function onClick(e){
    Request()
  }


  return (
    <Container>
      <h3>Manual </h3>
      <label>
          Url
          <input type="text" name="name" value={input} onChange={(e)=>setInput(e.target.value)} onKeyPress={(e)=>onKeyPress(e)}/>
      </label>
      Type: 
      <select value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
        <option value="movies">Movie</option>
        <option value="shows">Show</option>
      </select><br/>
      <button onClick={onClick}>Search</button>
    </Container>
      )
}