import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Container from 'react-bootstrap/Container'
import Search from './Search'
import Browse from './Browse'
import Home from './Home'
import Manual from './Manual'

function App(){
  const [key, setKey] = useState('home');
  return (
    <Container>
      <Tabs defaultActiveKey={key} transition={false} onSelect={k => setKey(k)} id="noanim-tab-example">
      <Tab eventKey="home" title="Home">
          <Home />
        </Tab>
        <Tab eventKey="search" title="Search">
          <Search />
        </Tab>
        <Tab eventKey="browse" title="Browse">
          <Browse/>
        </Tab>
        <Tab eventKey="manual" title="Manual">
          <Manual/>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default App